import React from 'react'
import { Link } from 'gatsby'

const Footer = () => (
  <div className="Footer"  >
    <div>
      <Link className="footer__logo" to="/">
        <div className="spinner-outer">
            <svg className="spinner-outer-svg"version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" width="180px"
              height="180px" viewBox="0 0 180 180" >

            <defs>
            </defs>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <path d="M17.92,64.35l-1.5,5.13l3.62,2.4l-0.57,1.95L5.54,64.39l0.48-1.64l16.81-0.4l-0.57,1.94L17.92,64.35z M14.88,68.47
                          l1.19-4.09L8.8,64.46L14.88,68.47z"/>
                        <path d="M31.17,44.23l4.91-6.1l1.35,1.08l-6.19,7.69L18.76,36.85l1.28-1.59L31.17,44.23z"/>
                        <path d="M30,24.08l7.43-6.22l1.14,1.36l-2.94,2.47l8.02,9.58l2.94-2.47l1.13,1.35l-7.43,6.22l-1.13-1.35l2.88-2.41l-8.02-9.58
                          l-2.88,2.41L30,24.08z"/>
                        <path d="M54.02,9.91l-4.34,2.38l6.86,12.53l-1.74,0.95l-6.86-12.53l-4.34,2.38l-0.83-1.53l10.43-5.7L54.02,9.91z"/>
                        <path d="M70.07,4.37l-4.73,1.48l4.26,13.63l-1.89,0.59L63.45,6.44l-4.73,1.48l-0.52-1.66l11.34-3.54L70.07,4.37z"/>
                        <path d="M79.73,15.48l7.79-0.82l0.18,1.72l-9.82,1.04L76.21,1.48l2.02-0.21L79.73,15.48z"/>
                        <path d="M101.18,9.8l-6.66-0.6l-0.51,5.66l7.77,0.7l-0.15,1.72l-9.8-0.88l1.43-15.96l9.7,0.87l-0.16,1.73l-7.67-0.69
                          l-0.46,5.13l6.66,0.6L101.18,9.8z"/>
                        <path d="M128.51,22.83c0.23-0.44,0.32-0.85,0.28-1.26c-0.04-0.4-0.16-0.79-0.37-1.16c-0.2-0.37-0.46-0.72-0.76-1.05
                          c-0.3-0.33-0.6-0.65-0.88-0.94c-0.41-0.43-0.82-0.89-1.22-1.39s-0.72-1.03-0.97-1.58c-0.25-0.56-0.39-1.13-0.42-1.72
                          c-0.03-0.59,0.11-1.19,0.43-1.8c0.32-0.61,0.74-1.08,1.27-1.42s1.09-0.56,1.7-0.66c0.61-0.1,1.23-0.09,1.88,0.02
                          c0.64,0.12,1.24,0.32,1.8,0.62c0.62,0.32,1.16,0.74,1.62,1.25s0.82,1.06,1.07,1.66c0.25,0.6,0.36,1.24,0.35,1.9
                          s-0.18,1.33-0.51,1.98l-1.85-0.97c0.16-0.44,0.26-0.86,0.29-1.27c0.03-0.41-0.02-0.8-0.14-1.17s-0.32-0.71-0.59-1.02
                          c-0.27-0.31-0.62-0.58-1.05-0.81c-0.34-0.18-0.71-0.31-1.08-0.38s-0.75-0.08-1.11-0.02c-0.36,0.06-0.69,0.19-0.99,0.4
                          c-0.3,0.21-0.55,0.5-0.75,0.87c-0.21,0.41-0.29,0.81-0.23,1.2c0.05,0.39,0.18,0.76,0.39,1.12c0.21,0.36,0.45,0.7,0.75,1.02
                          c0.29,0.32,0.57,0.61,0.84,0.88c0.29,0.3,0.58,0.61,0.86,0.93c0.28,0.33,0.54,0.67,0.78,1.03c0.24,0.36,0.44,0.72,0.6,1.1
                          c0.17,0.38,0.28,0.77,0.34,1.17c0.06,0.4,0.06,0.81,0,1.22c-0.06,0.41-0.21,0.83-0.43,1.25c-0.33,0.64-0.77,1.12-1.3,1.44
                          s-1.1,0.53-1.72,0.61c-0.61,0.08-1.25,0.05-1.89-0.09c-0.65-0.14-1.25-0.36-1.82-0.65c-0.63-0.33-1.19-0.75-1.7-1.27
                          c-0.5-0.51-0.89-1.08-1.18-1.7c-0.28-0.62-0.44-1.27-0.46-1.96c-0.02-0.69,0.14-1.37,0.47-2.06l1.84,0.97
                          c-0.17,0.47-0.26,0.93-0.26,1.36s0.08,0.85,0.24,1.24c0.16,0.39,0.4,0.74,0.71,1.06c0.31,0.32,0.69,0.59,1.13,0.82
                          c0.35,0.18,0.72,0.32,1.11,0.41c0.39,0.09,0.76,0.12,1.13,0.07c0.36-0.04,0.7-0.16,1.02-0.36
                          C128.04,23.51,128.3,23.22,128.51,22.83z"/>
                        <path d="M140.39,31.1l8.31-8.31l1.5,1.21l-11.6,11.22l-1.62-1.31l6-10.67l-9.22,8.07l-1.62-1.32l8.57-13.68l1.5,1.22
                          l-6.41,9.85l8.96-7.78l1.38,1.12L140.39,31.1z"/>
                        <path d="M153.53,40.75l-4.28-5.14l-4.36,3.63l4.99,6l-1.33,1.1l-6.29-7.56l12.32-10.25l6.23,7.49l-1.34,1.11l-4.93-5.92
                          l-3.96,3.29l4.28,5.14L153.53,40.75z"/>
                        <path d="M161.62,54.06l-3.19-5.88l-4.99,2.71l3.72,6.86l-1.52,0.82l-4.7-8.65l14.08-7.65l4.65,8.56l-1.53,0.83l-3.68-6.77
                          l-4.53,2.46l3.19,5.88L161.62,54.06z"/>
                        <path d="M174.1,68.57l-1.46-4.73l-13.65,4.22l-0.59-1.89l13.65-4.22l-1.46-4.73l1.66-0.51l3.51,11.35L174.1,68.57z"/>
                      </g>
                    </g>
                    <g>
                      <path d="M11.05,122.74l-1.89-5l-4.34,0.26l-0.72-1.91l16.82-0.81l0.6,1.6l-13.2,10.41l-0.71-1.89L11.05,122.74z M11.02,117.63
                        l1.5,3.99l5.76-4.43L11.02,117.63z"/>
                      <path d="M23.03,146.88l5.24,5.82l-1.28,1.16l-6.6-7.34l11.91-10.72l1.36,1.51L23.03,146.88z"/>
                      <path d="M41.56,145.55l7.69,5.88l-1.08,1.41l-3.05-2.33l-7.59,9.92l3.05,2.33l-1.07,1.4l-7.69-5.88l1.07-1.4l2.98,2.28
                        l7.59-9.92l-2.98-2.28L41.56,145.55z"/>
                      <path d="M61.53,160l-4.42-2.23l-6.43,12.75l-1.77-0.89l6.43-12.75l-4.42-2.23l0.78-1.55l10.61,5.35L61.53,160z"/>
                      <path d="M74.98,164.06l-4.76-1.37l-3.94,13.73l-1.9-0.55l3.94-13.73l-4.76-1.37l0.48-1.67l11.42,3.28L74.98,164.06z"/>
                      <path d="M79.28,177.27l7.8,0.73l-0.16,1.72l-9.83-0.92l1.49-15.95l2.03,0.19L79.28,177.27z"/>
                      <path d="M101.51,171.52l-6.66,0.6l0.51,5.66l7.77-0.7l0.15,1.72l-9.8,0.88l-1.44-15.96l9.7-0.88l0.16,1.73l-7.67,0.69
                        l0.46,5.13l6.66-0.6L101.51,171.52z"/>
                      <path d="M131.5,164.98c-0.22-0.44-0.51-0.76-0.86-0.96c-0.35-0.2-0.74-0.33-1.15-0.38c-0.42-0.05-0.85-0.05-1.3,0
                        c-0.45,0.05-0.87,0.11-1.27,0.17c-0.59,0.09-1.2,0.15-1.84,0.18c-0.64,0.03-1.25-0.01-1.85-0.13c-0.6-0.12-1.15-0.34-1.65-0.66
                        c-0.5-0.32-0.91-0.79-1.21-1.4c-0.31-0.62-0.45-1.24-0.42-1.86c0.03-0.62,0.18-1.21,0.45-1.76s0.64-1.06,1.11-1.51
                        c0.47-0.45,0.99-0.82,1.55-1.1c0.62-0.31,1.28-0.51,1.96-0.59c0.68-0.08,1.34-0.05,1.97,0.1s1.22,0.42,1.75,0.82
                        s0.97,0.92,1.31,1.57l-1.87,0.94c-0.26-0.39-0.55-0.71-0.87-0.97c-0.32-0.26-0.67-0.45-1.04-0.57
                        c-0.37-0.12-0.76-0.16-1.17-0.12c-0.41,0.04-0.83,0.17-1.27,0.38c-0.35,0.17-0.66,0.39-0.94,0.66
                        c-0.28,0.26-0.5,0.56-0.66,0.89c-0.16,0.33-0.25,0.67-0.26,1.04c-0.01,0.37,0.08,0.74,0.27,1.12c0.21,0.41,0.49,0.71,0.84,0.89
                        s0.72,0.3,1.13,0.34c0.41,0.04,0.83,0.04,1.26-0.02c0.43-0.05,0.83-0.11,1.2-0.17c0.41-0.07,0.83-0.12,1.26-0.16
                        c0.43-0.04,0.86-0.05,1.29-0.04c0.43,0.02,0.84,0.07,1.25,0.15c0.4,0.09,0.79,0.22,1.15,0.4c0.36,0.18,0.69,0.42,0.99,0.71
                        c0.3,0.29,0.55,0.65,0.77,1.08c0.32,0.64,0.46,1.28,0.41,1.9c-0.05,0.62-0.21,1.21-0.51,1.75c-0.29,0.54-0.69,1.04-1.18,1.49
                        s-1.02,0.81-1.59,1.1c-0.64,0.32-1.31,0.53-2.02,0.64c-0.71,0.11-1.4,0.1-2.06-0.03c-0.67-0.13-1.29-0.39-1.86-0.77
                        c-0.57-0.38-1.03-0.91-1.4-1.58l1.86-0.93c0.28,0.42,0.6,0.75,0.96,1s0.74,0.43,1.15,0.52c0.41,0.1,0.83,0.11,1.27,0.04
                        c0.44-0.07,0.88-0.21,1.33-0.44c0.35-0.18,0.68-0.4,0.98-0.66c0.3-0.26,0.54-0.55,0.71-0.87s0.28-0.67,0.3-1.04
                        C131.78,165.75,131.69,165.37,131.5,164.98z"/>
                      <path d="M144,156.37l-4.26,3.24l1.49,4.08l-1.62,1.23l-5.58-15.88l1.36-1.03l13.75,9.67l-1.61,1.23L144,156.37z M139.11,157.86
                        l3.39-2.58l-5.89-4.26L139.11,157.86z"/>
                      <path d="M153.55,150.84l5.26-5.81l1.28,1.16l-6.62,7.32l-11.88-10.75l1.37-1.51L153.55,150.84z"/>
                      <path d="M157.46,124.24l-2.59,4.22l12.18,7.47l-1.04,1.69l-12.18-7.47l-2.59,4.22l-1.48-0.91l6.21-10.13L157.46,124.24z"/>
                      <path d="M166.37,119.12l-6.22-6.27l0.82-2.16l7.62,8.23l5.57,2.15l-0.67,1.77l-5.6-2.09l-11.16,1.09l0.83-2.17L166.37,119.12z"
                        />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            </svg>
                      
            <svg className="footer__ps" version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" width="180px"
              height="180px" viewBox="0 0 180 180">

            <defs>
            </defs>
            <g>
              <path d="M81.39,81.5l-0.34-1.23c5.06-2.12,7.32-5.13,7.32-12.79c0-7.52-2.87-11.01-7.73-11.01c-1.44,0-2.12,0.07-3.56,0.34V86.7
                c0,10.46,0.48,11.35,5.13,12.31l2.26,0.48v1.37c-1.3,0-10.53-0.14-12.99-0.14c-2.46,0-10.46,0.14-11.83,0.14v-1.37l2.26-0.48
                c3.42-0.75,3.97-2.67,3.97-12.24V68.03c0-9.57-0.55-11.49-3.97-12.24l-2.26-0.48v-1.37c1.37,0,9.78,0.14,12.24,0.14
                c2.46,0,6.84-0.07,9.85-0.07c10.87,0,18.05,4.44,18.05,13.06C99.79,75.42,93.29,79.79,81.39,81.5z"/>
              <path d="M102.37,126.05c-6.36,0-9.64-0.75-13.54-2.19l-1.71-11.21h1.71l1.64,2.94c2.94,5.2,6.36,8.07,12.38,8.07
                c5.06,0,8.07-2.87,8.07-7.04c0-3.83-2.53-6.09-10.12-9.23c-8.75-3.62-12.86-7.25-12.86-14.7c0-8.21,7.38-14.36,16.48-14.36
                c5.74,0,8.48,0.62,11.97,1.91L118.16,92h-1.78l-1.57-3.08c-2.8-5.47-6.02-8.27-10.94-8.27c-3.9,0-6.5,2.74-6.5,6.36
                c0,4.03,2.74,6.09,8.96,8.68c9.71,4.03,14.02,7.25,14.02,15.11C120.35,119.97,111.46,126.05,102.37,126.05z"/>
            </g>
            </svg>

        </div>
      </Link>
    </div>
  </div>
)

export default Footer
